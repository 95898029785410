import { BaseState } from '../base.state';
import {
  Belt,
  ModularColour,
  ModularMaterial,
  ModularType,
  SyntheticMaterial,
} from '../../models/belt.model';

export class BeltState extends BaseState {
  public belts: Belt[];
  public syntheticMaterials: SyntheticMaterial[];
  public modularMaterials: ModularMaterial[];
  public modularTypes: ModularType[];
  public modularColours: ModularColour[];
}

export const initialState: BeltState = {
  belts: [],
  syntheticMaterials: [],
  modularMaterials: [],
  modularTypes: [],
  modularColours: [],
};
