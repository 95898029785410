import { Injectable } from '@angular/core';
import { TranslateService } from '../translate/translate.service';

@Injectable({ providedIn: 'root' })
export class BeltDropdownService {
  public constructor(private t: TranslateService) {}

  public beltJointTypes = [
    { _OPEN: this.t.translate('_OPEN') },
    { _PREPARED: this.t.translate('_PREPARED') },
    { _ENDLESS: this.t.translate('_ENDLESS') },
    { _TRULY_ENDLESS: this.t.translate('_TRULY_ENDLESS') },
    { _LACE: this.t.translate('_LACE') },
    { _HOOK: this.t.translate('_HOOK') },
    { _RS62: this.t.translate('_RS62') },
    { _RS125: this.t.translate('_RS125') },
    { _SPIRAL: this.t.translate('_SPIRAL') },
    { _ZIPLOCK: this.t.translate('_ZIPLOCK') },
    { _OTHER: this.t.translate('_OTHER') },
  ];

  public beltWeldTypes = [
    { _FINGER_SPLICE: this.t.translate('_FINGER_SPLICE') },
    { _BUSINESS_SPLICE: this.t.translate('_BUSINESS_SPLICE') },
    { _FINGER_OVER_FINGER: this.t.translate('_FINGER_OVER_FINGER') },
    { _STEP_SPLICE: this.t.translate('_STEP_SPLICE') },
    { _QUICK_SPLICE: this.t.translate('_QUICK_SPLICE') },
    { _CLASSIC: this.t.translate('_CLASSIC') },
  ];

  public beltWeldDirections = [
    { _STRAIGHT: this.t.translate('_STRAIGHT') },
    { _DIAGONAL: this.t.translate('_DIAGONAL') },
  ];

  public beltAccessTypes = [
    { _HEAD_HEIGHT: this.t.translate('_HEAD_HEIGHT') },
    { _TWO_METER_HEIGHT: this.t.translate('_TWO_METER_HEIGHT') },
    { _THREE_METER_HEIGHT: this.t.translate('_THREE_METER_HEIGHT') },
    { _FOUR_METER_HEIGHT: this.t.translate('_FOUR_METER_HEIGHT') },
    { _HOT_ENVIRONMENT: this.t.translate('_HOT_ENVIRONMENT') },
    { _COLD_ENVIRONMENT: this.t.translate('_COLD_ENVIRONMENT') },
    { _PERMANENT_GANTRY: this.t.translate('_PERMANENT_GANTRY') },
    { _DIFFICULT_ACCESS: this.t.translate('_DIFFICULT_ACCESS') },
    { _ENCLOSED_IN_CASING: this.t.translate('_ENCLOSED_IN_CASING') },
    { _SCISSOR_LIFT_PLATFORM_AVAILABLE: this.t.translate('_SCISSOR_LIFT_PLATFORM_AVAILABLE') },
  ];

  public beltCommentOptions = [
    { _NO_ACCESS: this.t.translate('_NO_ACCESS') },
    { _LENGTH_TBC: this.t.translate('_LENGTH_TBC') },
    { _WIDTH_TBC: this.t.translate('_WIDTH_TBC') },
    { _MATERIAL_TBC: this.t.translate('_MATERIAL_TBC') },
    { _ALL_INFO_TBC: this.t.translate('_ALL_INFO_TBC') },
    { _TRACKING_GUIDE_TBC: this.t.translate('_TRACKING_GUIDE_TBC') },
    { _DOES_BELT_HAVE_TRACKING_GUIDE: this.t.translate('_DOES_BELT_HAVE_TRACKING_GUIDE') },
    { _NOT_UNICHAINS: this.t.translate('_NOT_UNICHAINS') },
  ];
}
