import { take } from 'rxjs';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';

import * as BeltActions from '../../state/belt/belt.actions';
import * as CustomerActions from '../../state/customer/customer.actions';
import * as SurveyActions from '../../state/survey/survey.actions';

import { Image } from '../../shared/components/image-input/models/image';
import { Survey } from './../../models/survey.model';
import { Belt } from './../../models/belt.model';
import { Customer } from './../../models/customer.model';

import { State } from '../../state/core.state';
import { Model } from '../../../app/state/state-utils';

import { AmmscanApiService } from '../api/ammscanApi.service';
import { FetchService, VerifiedIds } from './fetch.service';
import { FileService } from '../file/file.service';
import { OnlineService } from '../online-service/online.service';
interface UploadData<T extends Model> {
  url: string;
  items: T[];
}

export class SynchronisationData {
  public customers?: Customer[] = [];
  public surveys?: Survey[] = [];
  public belts?: Belt[] = [];
}

@Injectable()
export class SyncService {
  private online: boolean;
  private fetchStartTime: number = null;

  public constructor(
    private store: Store,
    private fileService: FileService,
    private onlineService: OnlineService,
    private ammscanApiService: AmmscanApiService,
    private fetchService: FetchService,
  ) {}

  public init(): void {
    this.onlineService.subscribe(async (online) => {
      this.online = online;
      if (online) {
        await this.trySyncUpToApi();
        await this.trySyncDownFromApi();
      }
    });
  }

  public async trySyncDownFromApi(): Promise<void> {
    const now = new Date().getTime();
    const busy = this.fetchStartTime && now - this.fetchStartTime < 5000;
    if (!this.online || busy) return;

    this.fetchStartTime = now;
    try {
      await this.fetchService.fetchServerDataAndUpdateState();
    } catch (err) {
      console.log(err);
    }
    this.fetchStartTime = null;
  }

  public async trySyncUpToApi(): Promise<void> {
    if (!this.online) return;

    const stateSnapshot = await this.getStateSnapshot();
    await this.synchronizeCustomers(stateSnapshot.customer.customers);
    await this.synchronizeSurveys(stateSnapshot.survey.surveys);
    await this.synchronizeBeltsAndImages(stateSnapshot.belt.belts);
  }

  private getStateSnapshot(): Promise<State> {
    return new Promise((resolve) => {
      this.store
        .select((state: State) => state)
        .pipe(take(1))
        .subscribe((state) => {
          resolve(state);
        });
    });
  }

  public async verifyData(): Promise<void> {
    const verifiedIds: VerifiedIds = await this.fetchService.fetchVerifiedIds();
    const stateSnapshot = await this.getStateSnapshot();

    const unverifiedCustomers = this.verifyStateData(
      stateSnapshot.customer.customers,
      verifiedIds.customerIds,
    );
    const unverifiedSurveys = this.verifyStateData(
      stateSnapshot.survey.surveys,
      verifiedIds.surveyIds,
    );
    const unverifiedBelts = this.verifyStateData(stateSnapshot.belt.belts, verifiedIds.beltIds);

    if (unverifiedCustomers.length)
      this.store.dispatch(
        CustomerActions.updateCustomersSuccess({ customers: unverifiedCustomers }),
      );
    if (unverifiedSurveys.length)
      this.store.dispatch(SurveyActions.updateSurveysSuccess({ surveys: unverifiedSurveys }));
    if (unverifiedBelts.length)
      this.store.dispatch(BeltActions.updateBeltsSuccess({ belts: unverifiedBelts }));
    await this.trySyncUpToApi();
  }

  private verifyStateData<T extends Model>(stateData: T[], verifiedIds: string[]): T[] {
    const dirtyItems: T[] = [];
    for (let stateItem of stateData) {
      const match = verifiedIds.find((id) => id == stateItem.id);
      if (!match) {
        if (!stateItem.dirty) {
          stateItem = { ...stateItem };
          stateItem.dirty = true;
          dirtyItems.push(stateItem);
        }
      }
    }
    return dirtyItems;
  }

  private async synchronizeSurveys(allSurveys: Survey[]): Promise<void> {
    const dirtySurveys = allSurveys.filter((survey) => survey.dirty);
    if (!dirtySurveys.length) return;

    const surveys = await this.tryUploadData({
      url: '/sync/push-client-surveys',
      items: dirtySurveys,
    });

    this.store.dispatch(SurveyActions.updateSurveysSuccess({ surveys }));
  }

  private async synchronizeCustomers(allCustomers: Customer[]): Promise<void> {
    const dirtyCustomers = allCustomers.filter((customer) => customer.dirty);
    if (!dirtyCustomers.length) return;

    const customers = await this.tryUploadData({
      url: '/sync/push-client-customers',
      items: dirtyCustomers,
    });

    this.store.dispatch(CustomerActions.updateCustomersSuccess({ customers }));
  }

  private async synchronizeBeltsAndImages(allBelts: Belt[]): Promise<void> {
    await this.uploadBeltImages(allBelts);

    const stateSnapshot = await this.getStateSnapshot();
    await this.synchronizeBelts(stateSnapshot.belt.belts);
  }

  private async uploadBeltImages(allBelts: Belt[]): Promise<void> {
    const beltsWithUnSyncedImages = allBelts.filter((x) => x.image);

    for (const belt of beltsWithUnSyncedImages) {
      await this.uploadBeltImage(belt.id, belt.image);
    }
  }

  private async uploadBeltImage(beltId: string, image: Image): Promise<void> {
    try {
      const { downloadUrl } = await this.fileService.uploadImage(image.file);
      const attachmentUrl = downloadUrl;
      this.store.dispatch(BeltActions.uploadBeltImageSuccess({ beltId, attachmentUrl }));
    } catch (err) {
      console.log(err);
    }
  }

  private async synchronizeBelts(allBelts: Belt[]): Promise<void> {
    const dirtyBelts = allBelts.filter((belt) => belt.dirty);
    if (!dirtyBelts.length) return;

    const belts = await this.tryUploadData({
      url: '/sync/push-client-belts',
      items: dirtyBelts,
    });

    this.store.dispatch(BeltActions.updateBeltsSuccess({ belts }));
  }

  private async tryUploadData<T extends Model>(data: UploadData<T>): Promise<T[]> {
    const chunks = this.chunkArray(data.items);
    const syncedItems: T[] = [];

    for (const chunk of chunks) {
      try {
        const items = await this.ammscanApiService.post<T[]>(data.url, chunk, {});
        syncedItems.push(...items);
      } catch (err) {
        console.log(err);
        continue;
      }
    }

    return syncedItems;
  }

  private chunkArray<T extends Model>(array: T[]): T[][] {
    const chunks = [];

    while (array.length) {
      const chunk = array.splice(0, 20);
      chunks.push(chunk);
    }

    return chunks;
  }
}
