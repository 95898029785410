<div class="desktop">
  <app-desktop-header
    [header]="t.translate('_TRANSLATIONS')"
    [showBackButton]="false">
    <div content>
      <button
        class="d-flex align-items-center btn btn-primary px-4"
        (click)="onSave()">
        {{ t.translate("_SAVE") }}
      </button>
    </div>
  </app-desktop-header>
  <div class="mx-lg-5 d-flex justify-content-between flex-column mt-lg-6">
    <div class="position-relative">
      <div class="bg-white border w-100 p-4 mb-5 rounded">
        <div class="flex-1 fw-bold mb-3">
          {{ t.translate("_LANGUAGE") }}
        </div>
        <div
          class="w-100 position-relative d-flex align-items-center mb-5"
          *ngIf="language">
          <div class="select-holder">
            <select
              class="select w-100 p-2 pe-4"
              [value]="language.id"
              (change)="onLanguageChanged($event)">
              <option
                *ngFor="let lang of languages"
                [value]="lang.id">
                {{ lang.title }}
              </option>
            </select>
          </div>
        </div>

        <div class="table-container border-top">
          <table class="table table-responsive">
            <thead>
              <tr class="text-capitalize small position-sticky top-0">
                <th class="col-5 user-select-none small p-3 fw-300 border-bottom">
                  <div class="d-flex align-items-center">
                    <span>
                      {{ t.translate("_NAME") }}
                    </span>
                  </div>
                </th>
                <th class="small p-3 fw-300 border-bottom">
                  <div class="d-flex align-items-center">
                    <span>
                      {{ t.translate("_TRANSLATION") }}
                    </span>
                    <i
                      class="sort-icon cursor-pointer ms-1 material-symbols-outlined"
                      [class.text-warning]="sortField == 'key'"
                      [class.invert]="'key' == sortField && sortDirection == 'DESC'">
                      sort
                    </i>
                  </div>
                </th>
              </tr>
            </thead>

            <tbody class="translations">
              <tr *ngFor="let translation of translations">
                <td class="key">
                  {{ translation.key }}
                </td>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="translation.translation" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="mobile">
  <app-mobile-header
    [header]="t.translate('_TRANSLATIONS')"
    [addSpacer]="true">
    <div content>
      <button
        class="d-flex align-items-center btn btn-primary px-4"
        (click)="onSave()">
        {{ t.translate("_SAVE") }}
      </button>
    </div>
  </app-mobile-header>
  <div class="mx-2 d-flex justify-content-between flex-column">
    <div class="position-relative">
      <div class="bg-white border p-4 mb-5 mt-3 rounded">
        <div class="flex-1 fw-bold mb-3">
          {{ t.translate("_LANGUAGE") }}
        </div>
        <div
          class="w-100 position-relative d-flex align-items-center mb-5"
          *ngIf="language">
          <div class="select-holder">
            <select
              class="select bg-white w-100 p-2 pe-4"
              [value]="language.id"
              (change)="onLanguageChanged($event)">
              <option
                *ngFor="let lang of languages"
                [value]="lang.id">
                {{ lang.title }}
              </option>
            </select>
          </div>
        </div>

        <div class="table-container border-top">
          <table class="table table-responsive mobile-table">
            <thead>
              <tr>
                <th class="small p-3 fw-300 border-bottom">
                  <div class="d-flex align-items-center">
                    <span>
                      {{ t.translate("_NAME") }}
                    </span>
                  </div>
                </th>
                <th class="small p-3 fw-300 border-bottom">
                  <div class="d-flex align-items-center">
                    <span>
                      {{ t.translate("_TRANSLATION") }}
                    </span>
                    <i
                      class="sort-icon cursor-pointer ms-1 material-symbols-outlined"
                      [class.text-warning]="sortField == 'key'"
                      [class.invert]="'key' == sortField && sortDirection == 'DESC'">
                      sort
                    </i>
                  </div>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let translation of translations">
                <td class="key wrap-word">
                  {{ translation.key }}
                </td>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="translation.translation" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
