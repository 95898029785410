import { createFeatureSelector, createSelector } from '@ngrx/store';

import { UserState } from './user.state';

export const selectUser = createFeatureSelector<UserState>('user');

export const selectCurrentUser = createSelector(
  selectUser,
  (state: UserState) => state.currentUser,
);

export const selectLoading = createSelector(selectUser, (state: UserState) => state.loading);

export const selectIsLoggedIn = createSelector(selectCurrentUser, (currentUser) => !!currentUser);

export const selectPasswordResetInitiated = createSelector(
  selectUser,
  (state: UserState) => !!state.passwordResetInitiated,
);

export const selectPasswordResetCompleted = createSelector(
  selectUser,
  (state: UserState) => !!state.passwordResetCompleted,
);
