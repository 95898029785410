import { Router } from '@angular/router';
import { Component, OnDestroy, AfterViewInit, ChangeDetectorRef } from '@angular/core';

import { environment } from './../../../environments/environment';
import { Animations } from './../../services/animations/animations';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [Animations.slideInOut(), Animations.fadeInOut()],
})
export class SidebarComponent implements AfterViewInit, OnDestroy {
  public isSidebarOpen: boolean = false;
  public isProduction: boolean = true;
  public isScreenSmall: boolean = true;
  public resizeListener;

  public constructor(
    private cdr: ChangeDetectorRef,
    public router: Router,
  ) {}

  public ngOnInit(): void {
    this.isProduction = environment.production;
  }

  public ngAfterViewInit(): void {
    this.checkScreenSize();
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    this.resizeListener = () => this.checkScreenSize();
    window.addEventListener('resize', this.resizeListener, true);
    this.cdr.detectChanges();
  }

  public ngOnDestroy(): void {
    window.removeEventListener('resize', this.resizeListener);
  }

  public toggleSidebar(): void {
    this.isSidebarOpen = !this.isSidebarOpen;
  }

  public checkScreenSize(): void {
    this.setIsSmallScreen(document.body.offsetWidth < 992);
  }

  private setIsSmallScreen(isSmallScreen: boolean): void {
    if (this.isScreenSmall != isSmallScreen) {
      this.isScreenSmall = isSmallScreen;
    }
  }

  public showHamburgerMenu(): boolean {
    return (
      this.isScreenSmall && (this.router.url == '/surveys' || this.router.url == '/translations')
    );
  }
}
