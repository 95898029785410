/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BeltState } from './belt.state';

export const selectBelts = createFeatureSelector<BeltState>('belt');

export const selectAllBelts = createSelector(selectBelts, (state: BeltState) =>
  state.belts.filter((belt) => !belt.deleted),
);

export const selectSyntheticMaterials = createSelector(
  selectBelts,
  (state: BeltState) => state.syntheticMaterials,
);

export const selectModularMaterials = createSelector(
  selectBelts,
  (state: BeltState) => state.modularMaterials,
);

export const selectModularTypes = createSelector(
  selectBelts,
  (state: BeltState) => state.modularTypes,
);

export const selectModularColours = createSelector(
  selectBelts,
  (state: BeltState) => state.modularColours,
);

export const selectBeltById = (props: { id: string }) =>
  createSelector(selectAllBelts, (belts) => belts.find((belt) => belt.id === props.id));

export const selectSurveyBelts = (props: { surveyId: string }) =>
  createSelector(selectAllBelts, (belts) =>
    belts.filter((belt) => belt.surveyId === props.surveyId),
  );
