import { Action, createReducer, on } from '@ngrx/store';

import { StateUtils } from './../state-utils';
import { BeltState, initialState } from './belt.state';

import * as BeltActions from './belt.actions';
import * as UserActions from '../user/user.actions';

const beltReducer = createReducer(
  initialState,

  on(BeltActions.resetBeltState, () => ({
    ...initialState,
  })),

  on(BeltActions.fetchBelts, (state) => ({
    ...state,
    loading: true,
  })),

  on(BeltActions.fetchBeltsSuccess, (state, { belts }) => {
    return {
      ...state,
      belts: StateUtils.combineStateArr(state.belts, belts),
      loading: false,
      loadedAt: new Date(),
    };
  }),

  on(BeltActions.fetchBeltsFailed, (state) => ({
    ...state,
    loading: false,
  })),

  on(BeltActions.fetchSyntheticMaterials, (state) => ({
    ...state,
    loading: true,
  })),

  on(BeltActions.fetchSyntheticMaterialsSuccess, (state, { syntheticMaterials }) => {
    return {
      ...state,
      syntheticMaterials,
      loading: false,
      loadedAt: new Date(),
    };
  }),

  on(BeltActions.fetchSyntheticMaterialsFailed, (state) => ({
    ...state,
    loading: false,
  })),

  on(BeltActions.fetchModularMaterials, (state) => ({
    ...state,
    loading: true,
  })),

  on(BeltActions.fetchModularMaterialsSuccess, (state, { modularMaterials }) => {
    return {
      ...state,
      modularMaterials,
      loading: false,
      loadedAt: new Date(),
    };
  }),

  on(BeltActions.fetchModularMaterialsFailed, (state) => ({
    ...state,
    loading: false,
  })),

  on(BeltActions.fetchModularTypes, (state) => ({
    ...state,
    loading: true,
  })),

  on(BeltActions.fetchModularTypesSuccess, (state, { modularTypes }) => {
    return {
      ...state,
      modularTypes,
      loading: false,
      loadedAt: new Date(),
    };
  }),

  on(BeltActions.fetchModularTypesFailed, (state) => ({
    ...state,
    loading: false,
  })),

  on(BeltActions.fetchModularColours, (state) => ({
    ...state,
    loading: true,
  })),

  on(BeltActions.fetchModularColoursSuccess, (state, { modularColours }) => {
    return {
      ...state,
      modularColours,
      loading: false,
      loadedAt: new Date(),
    };
  }),

  on(BeltActions.fetchModularColoursFailed, (state) => ({
    ...state,
    loading: false,
  })),

  on(BeltActions.fetchBeltById, (state) => ({
    ...state,
    loading: true,
  })),

  on(BeltActions.fetchBeltSuccess, (state, { belt }) => ({
    ...state,
    belts: StateUtils.combineStateArr(state.belts, [belt]),
    loading: false,
    loadedAt: new Date(),
  })),

  on(BeltActions.fetchBeltFailed, (state) => ({
    ...state,
    loading: false,
  })),

  on(BeltActions.addBeltSuccess, (state, { belt }) => {
    return {
      ...state,
      belts: StateUtils.combineStateArr(state.belts, [belt]),
      loading: false,
      loadedAt: new Date(),
    };
  }),

  on(BeltActions.addBeltsSuccess, (state, { belts }) => {
    return {
      ...state,
      belts: StateUtils.combineStateArr(state.belts, belts),
      loading: false,
      loadedAt: new Date(),
    };
  }),

  on(BeltActions.setBeltsSuccess, (state, { belts }) => {
    return {
      ...state,
      belts,
      loading: false,
      loadedAt: new Date(),
    };
  }),

  on(BeltActions.updateBeltSuccess, (state, { belt }) => {
    return {
      ...state,
      belts: StateUtils.combineStateArr(state.belts, [belt]),
      loading: false,
      loadedAt: new Date(),
    };
  }),

  on(BeltActions.uploadBeltImageSuccess, (state, { beltId, attachmentUrl }) => {
    let belt = state.belts.find((belt) => belt.id == beltId);

    belt = { ...belt, attachmentUrl, dirty: true };
    delete belt.image;

    return {
      ...state,
      belts: StateUtils.combineStateArr(state.belts, [belt]),
      loading: false,
      loadedAt: new Date(),
    };
  }),

  on(BeltActions.updateBeltsSuccess, (state, { belts }) => {
    return {
      ...state,
      belts: StateUtils.combineStateArr(state.belts, belts),
      loading: false,
      loadedAt: new Date(),
    };
  }),

  on(BeltActions.removeBeltSuccess, (state, { belt }) => {
    return {
      ...state,
      belts: StateUtils.combineStateArr(state.belts, [belt]),
      loading: false,
      loadedAt: new Date(),
    };
  }),

  on(UserActions.logoutSuccess, () => {
    return initialState;
  }),
);

export function reducer(state: BeltState | undefined, action: Action): BeltState {
  return beltReducer(state, action);
}
