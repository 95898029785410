import { BrowserCacheLocation } from '@azure/msal-browser';

import { environment } from 'src/environments/environment';
import { MsalScope } from './services/msal-token/msal-token.service';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export const b2cAmmscanPolicies = {
  names: {
    signUpSignIn: 'B2C_1_Ammscan_Sign_In',
    resetPassword: 'B2C_1_Ammscan_Sign_In',
    editProfile: 'B2C_1_Ammscan_Sign_In',
  },
  authorities: {
    signUpSignIn: {
      authority:
        'https://connectedbelt.b2clogin.com/connectedbelt.onmicrosoft.com/B2C_1_Ammscan_Sign_In',
    },
    resetPassword: {
      authority: 'https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/B2C_1_reset_v3',
    },
    editProfile: {
      authority:
        'https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/b2c_1_edit_profile_v2',
    },
  },
  authorityDomain: 'connectedbelt.b2clogin.com',
};

export default class AuthMsalConfig {
  public static fetchDesktopConfig(): {
    auth: {
      clientId: string;
      authority: string;
      knownAuthorities: string[];
      redirectUri: string;
      postLogoutRedirectUri: string;
      navigateToLoginRequestUrl: boolean;
    };
    cache: {
      cacheLocation: BrowserCacheLocation;
      storeAuthStateInCookie: boolean;
    };
    system: {
      allowNativeBroker: boolean;
    };
  } {
    const ammscanClientId = environment.ammscanClientId;
    const loginRedirectUri = environment.loginRedirectUri;
    const logoutRedirectUri = environment.logoutRedirectUri;
    const policies = b2cAmmscanPolicies;

    return {
      auth: {
        clientId: ammscanClientId,
        authority: policies.authorities.signUpSignIn.authority,
        knownAuthorities: [policies.authorityDomain],
        redirectUri: loginRedirectUri,
        postLogoutRedirectUri: logoutRedirectUri,
        navigateToLoginRequestUrl: true,
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: isIE,
      },
      system: {
        allowNativeBroker: false,
      },
    };
  }

  public static fetchMobileConfig(msalScope: MsalScope): {
    authorities: {
      type: string;
      authorityUrl: string;
      cloudInstance: string;
      default: boolean;
    }[];
    authorizationUserAgent: string;
    multipleCloudsSupported: boolean;
    brokerRedirectUri: boolean;
    accountMode: string;
    scopes: string[];
    webViewZoomControlsEnabled: boolean;
    webViewZoomEnabled: boolean;
    powerOptCheckForNetworkReqEnabled: boolean;
    clientId: string;
    tenantId: string;
  } {
    let scope: string;
    if (msalScope == MsalScope.ammscan) scope = environment.ammscanApiScope;
    if (msalScope == MsalScope.cBelt) scope = environment.cBeltClientId;

    const authority =
      'https://connectedbelt.b2clogin.com/tfp/connectedbelt.onmicrosoft.com/B2C_1_Connected_Belt/';

    return {
      authorities: [
        {
          type: 'B2C',
          authorityUrl: authority,
          cloudInstance: 'MSALAzurePublicCloudInstance',
          default: true,
        },
      ],
      authorizationUserAgent: 'WEBVIEW',
      multipleCloudsSupported: false,
      brokerRedirectUri: false,
      accountMode: 'SINGLE',
      scopes: [scope],
      webViewZoomControlsEnabled: false,
      webViewZoomEnabled: false,
      powerOptCheckForNetworkReqEnabled: true,
      clientId: environment.cBeltClientId,
      tenantId: 'common',
    };
  }
}
