import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Observable, Subscriber, Subscription } from 'rxjs';

import { TranslateService } from '../../../services/translate/translate.service';
import { UtilService } from '../../../utils/util.service';

@Injectable()
export class BreadcrumbService {
  private breadcrumbs$: Observable<Breadcrumb[]> = new Observable<Breadcrumb[]>();
  private breadcrumbs: Breadcrumb[] = [];
  private subscribers: Subscriber<Breadcrumb[]>[] = [];

  public constructor(
    private router: Router,
    private utils: UtilService,
    private t: TranslateService
  ) {
    this.breadcrumbs$ = new Observable<Breadcrumb[]>((sub) => {
      this.subscribers.push(sub);
      sub.next(this.breadcrumbs);
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart && event.navigationTrigger == 'popstate') {
        const crumbs = this.breadcrumbs.map((crumb) => crumb.routerLink.join('/'));
        if (crumbs.find((crumb) => crumb.includes(event.url))) {
          this.popBreadcrumb();
        }
      }
    });
  }

  public popBreadcrumb(): void {
    this.breadcrumbs.pop();
    this.updateBreadcrumbsObservable();

    const length = this.breadcrumbs.length;
    const history = this.breadcrumbs[length - 1];

    const breadcrumb: Breadcrumb = history || {
      name: 'Overview',
      routerLink: ['/'],
      queryParams: {},
    };
    this.router.navigate(breadcrumb.routerLink, { queryParams: breadcrumb.queryParams });
  }

  public addQueryParamsToBreadcrumb(breadcrumb: Breadcrumb, params: string[]): void {
    const crumb = this.breadcrumbs.find((crumb) => crumb.name == breadcrumb.name);
    if (!crumb) return;
    
    crumb.queryParams = params;
    this.updateBreadcrumbsObservable();
  }

  public removeBreadcrumbsAfterBreadcrumb(breadcrumb: Breadcrumb): void {
    const index = this.breadcrumbs.indexOf(breadcrumb);
    this.breadcrumbs = this.breadcrumbs.slice(0, index);
    this.updateBreadcrumbsObservable();
  }

  public pushBreadcrumb(breadcrumb: Breadcrumb): void {
    if (this.breadcrumbs.find((crumb) => (crumb.name === breadcrumb.name))) {
      return;
    }

    breadcrumb = this.translateComponentBreadcrumbs(breadcrumb);
    this.breadcrumbs.push(breadcrumb);
    this.updateBreadcrumbsObservable();
  }

  public setBreadcrumbs(breadcrumbs: Breadcrumb[]): void {
    this.breadcrumbs = breadcrumbs;
    this.updateBreadcrumbsObservable();
  }

  private updateBreadcrumbsObservable(): void {
    for (const subscriber of this.subscribers) {
      subscriber.next(this.utils.deepCopy(this.breadcrumbs));
    }
  }

  public subscribeToBreadcrumbChanges(callback: (breadcrumbs: Breadcrumb[]) => void): Subscription {
    return this.breadcrumbs$.subscribe(callback);
  }

  public unsubscribe(subscription: Subscription): void {
    if (subscription) {
      subscription.unsubscribe();
    }

    this.subscribers = this.subscribers.filter((subscriber) => !subscriber.closed);
  }

  public translateComponentBreadcrumbs(breadcrumb: Breadcrumb): Breadcrumb {
    if (breadcrumb.name === 'Surveys') {
      breadcrumb.name = this.t.translate('_SURVEYS');
    }
    return breadcrumb;
  }
}

export class Breadcrumb {
  public routerLink: string[];
  public name: string;
  public queryParams?: unknown;

  public constructor(name: string, routerLink: string[]) {
    this.name = name;
    this.routerLink = routerLink;
  }
}
