import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { Injectable, NgZone } from '@angular/core';
import { filter, firstValueFrom } from 'rxjs';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionRequiredAuthError,
} from '@azure/msal-browser';
import { Store } from '@ngrx/store';
import jwt_decode from 'jwt-decode';

import { environment } from 'src/environments/environment';
import AuthMsalConfig from 'src/app/auth-config';

import * as UserActions from 'src/app/state/user/user.actions';

import { UtilService } from 'src/app/utils/util.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { StorageService } from 'src/app/services/storage/storage.service';

export enum MsalScope {
  cBelt = 'cBelt',
  ammscan = 'ammscan',
}

@Injectable({ providedIn: 'root' })
export class MsalTokenService {
  public static isMobileApp(): boolean {
    return !!window['cordova'];
  }

  private cBeltToken: string;
  private ammscanToken: string;
  private scope: MsalScope;

  public constructor(
    private store: Store,
    private ngZone: NgZone,
    private msalService: MsalService,
    private authService: AuthService,
    private msalBroadcastService: MsalBroadcastService,
    private utils: UtilService,
    private storageService: StorageService,
  ) {}

  public async init(scope: MsalScope): Promise<void> {
    this.scope = scope;

    if (!MsalTokenService.isMobileApp()) {
      await firstValueFrom(this.msalService.initialize());
      this.listenForBroadcastUpdates();
    }
  }

  public async setScope(scope: MsalScope): Promise<void> {
    if (this.scope == scope) return;

    this.scope = scope;
    if (MsalTokenService.isMobileApp()) {
      await this.authenticate();
    }
  }

  /* Msal Login */

  public async authenticate(): Promise<void> {
    if (MsalTokenService.isMobileApp()) {
      await this.mobileInitAndLogin();
    } else {
      await this.webLogin();
    }
  }

  private async mobileInitAndLogin(): Promise<void> {
    await this.mobileScopeInit();
    await this.mobileLogin();
  }

  private async mobileScopeInit(): Promise<void> {
    return new Promise((resolve, reject) => {
      const config: any = AuthMsalConfig.fetchMobileConfig(this.scope);
      window['cordova'].plugins.msalPlugin.msalInit(
        () => resolve(),
        () => reject(),
        config,
      );
    });
  }

  private mobileLogin(): Promise<void> {
    return new Promise((resolve) => {
      window['cordova'].plugins.msalPlugin.signInSilent(
        (resp) =>
          this.ngZone.run(async () => {
            await this.onMobileLoginSuccess(resp);
            resolve();
          }),
        () =>
          this.ngZone.run(async () => {
            await this.interactiveMobileLogin();
            resolve();
          }),
      );
    });
  }

  private async onMobileLoginSuccess(response): Promise<void> {
    const userData = this.getUserDetails(response);
    this.setToken(userData.token);
    await this.authService.onMsalAuthChange(userData, {
      fetchOrgPermissions: true,
    });
  }

  private getUserDetails(response): { id: string; email: string; token: string } {
    let id: string;
    let email: string;
    const claims = response.account.claims;
    for (const claim of claims) {
      if (claim.key == 'sub') {
        id = claim.value as string;
      }
      if (claim.key == 'emails') {
        email = claim.value[0];
      }
    }
    return {
      id: id,
      email: email,
      token: response.token,
    };
  }

  private interactiveMobileLogin(): Promise<void> {
    return new Promise((resolve) => {
      window['cordova'].plugins.msalPlugin.signInInteractive(
        (resp) =>
          this.ngZone.run(async () => {
            await this.onMobileLoginSuccess(resp);
            resolve();
          }),
        () => {
          this.ngZone.run(async () => {
            await this.logout();
            resolve();
          });
        },
        { prompt: 'LOGIN' },
      );
    });
  }

  private async webLogin(): Promise<void> {
    let accounts = this.msalService.instance.getAllAccounts();
    let count = 0;
    while (!accounts.length && count < 50) {
      await this.utils.sleep(100);
      accounts = this.msalService.instance.getAllAccounts();
      count++;
    }
    if (!accounts.length) throw new Error('No accounts found');

    try {
      const result = await firstValueFrom(
        this.msalService.acquireTokenSilent({
          scopes: [environment.ammscanApiScope],
          account: accounts[0],
        }),
      );

      await this.onWebLoginSuccess(result);
    } catch (err) {
      if (err instanceof InteractionRequiredAuthError) {
        await this.logout(environment.loginRedirectUri);
        this.msalService.acquireTokenRedirect({
          scopes: [environment.ammscanApiScope],
        });
      } else {
        throw err;
      }
    }
  }

  private listenForBroadcastUpdates(): void {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType == EventType.LOGIN_SUCCESS ||
            msg.eventType == EventType.ACQUIRE_TOKEN_SUCCESS,
        ),
      )
      .subscribe((result: EventMessage) => {
        this.onWebLoginSuccess(result.payload as AuthenticationResult);
      });

    this.msalBroadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType == EventType.LOGOUT_SUCCESS))
      .subscribe(() => {
        this.store.dispatch(UserActions.logoutSuccess());
        this.msalService.instance.setActiveAccount(null);
        this.authService.onMsalAuthChange(null);
        this.storageService.clear();
      });
  }

  private async onWebLoginSuccess(result: AuthenticationResult): Promise<void> {
    try {
      if (!result.accessToken) return;

      const userData = {
        id: result.uniqueId,
        email: result.account.username,
      };

      this.setToken(result.accessToken);
      this.msalService.instance.setActiveAccount(result.account);
      await this.authService.onMsalAuthChange(userData);
    } catch (err) {
      console.log(err);
    }
  }

  public async logout(redirectUri?: string): Promise<void> {
    if (MsalTokenService.isMobileApp()) {
      await this.logoutMobile();
      await this.interactiveMobileLogin();
    } else {
      return this.logoutWeb(redirectUri);
    }
  }

  private logoutMobile(): Promise<void> {
    return new Promise((resolve) => {
      window['cordova'].plugins.msalPlugin.signOut(
        () =>
          this.ngZone.run(async () => {
            await this.onMobileLogoutSuccess();
            resolve();
          }),
        () =>
          this.ngZone.run(() => {
            this.interactiveMobileLogin();
            resolve();
          }),
      );
    });
  }

  private async onMobileLogoutSuccess(): Promise<void> {
    await this.authService.onMsalAuthChange(null);
    this.store.dispatch(UserActions.logoutSuccess());
  }

  private async logoutWeb(redirectUri: string): Promise<void> {
    await this.msalService.instance.logoutRedirect({
      account: this.msalService.instance.getActiveAccount(),
      postLogoutRedirectUri: redirectUri || environment.logoutRedirectUri,
    });
  }

  /* Msal Token */

  private setToken(token: string): void {
    if (this.scope == MsalScope.cBelt) this.cBeltToken = token;
    if (this.scope == MsalScope.ammscan) {
      this.ammscanToken = token;
      this.storageService.setAmmscanToken(token);
    }
  }

  public async getToken(scope: MsalScope): Promise<string> {
    const token = this.getTokenForScope(scope);
    const valid = this.isTokenValid(token);
    if (valid) return token;

    if (token && scope == this.scope) return this.getRefreshedToken();
    return null;
  }

  private getTokenForScope(scope: MsalScope): string {
    if (scope == MsalScope.cBelt) return this.cBeltToken;
    if (scope == MsalScope.ammscan) return this.ammscanToken;
    return null;
  }

  public isTokenValid(token: string): boolean {
    if (!token) return false;

    try {
      const tokenPayload: any = jwt_decode(token);
      const tokenExpiration = tokenPayload?.exp;
      const now = Math.floor(Date.now() / 1000);

      return tokenExpiration ? tokenExpiration > now : true;
    } catch (e) {
      console.error('Failed to decode JWT', e);
      return false;
    }
  }

  private async getRefreshedToken(): Promise<string> {
    if (MsalTokenService.isMobileApp()) {
      return this.mobileRefreshToken();
    } else {
      return this.webRefreshToken();
    }
  }

  private async mobileRefreshToken(): Promise<string> {
    await this.mobileLogin();
    return this.getTokenForScope(this.scope);
  }

  private async webRefreshToken(): Promise<string> {
    try {
      const acquireTokenObservable = this.msalService.acquireTokenSilent({
        scopes: [environment.ammscanApiScope],
        account: this.msalService.instance.getActiveAccount(),
      });

      const result = await this.utils.observableToPromise(acquireTokenObservable);

      await this.onWebLoginSuccess(result);
      return result.accessToken;
    } catch (err) {
      if (err instanceof InteractionRequiredAuthError) {
        this.msalService.acquireTokenRedirect({
          scopes: [environment.ammscanApiScope],
        });
      }
      throw err;
    }
  }
}
