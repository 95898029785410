import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { Language } from '../../models/language';
import { Translation } from '../../models/translation';

import { AlertService } from '../../shared/services/alert/alert.service';
import { OnlineService } from '../../services/online-service/online.service';
import { LoadingService } from '../../shared/services/loading/loading.service';
import { TranslateContext, TranslateService } from '../../services/translate/translate.service';

import * as keys from '../../../../_files/keys.json';

@Component({
  selector: 'app-translations',
  templateUrl: './translations.component.html',
  styleUrls: ['./translations.component.scss'],
})
export class TranslationsComponent implements OnInit, OnDestroy {
  public language: Language;
  public languages: Language[] = [];
  public translations: Translation[] = [];

  public sortField: string = 'key';
  public sortDirection: string = 'ASC';

  public online: boolean;
  public loading: boolean = false;

  private keys: string[] = (keys as any).default.sort();
  private sub: Subscription;

  public constructor(
    private alertService: AlertService,
    private translateService: TranslateService,
    private onlineService: OnlineService,
    private loadingService: LoadingService,
    public t: TranslateService,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.subscribeToLanguages();

    this.online = await this.onlineService.canConnectToApi();
    if (!this.online) {
      const translationData = this.translateService.returnTranslations();
      this.translations = translationData.translations;
    }
  }

  public ngOnDestroy(): void {
    if (this.sub) this.sub.unsubscribe();
  }

  private subscribeToLanguages(): void {
    this.sub = this.t.subscribeToTranslationData((data) => {
      this.languages = data.languages;
      if (this.languages.length) {
        this.updateLanguage(this.languages[0]);
      }
    });
  }

  public onLanguageChanged(event: { target: { value: number } }): void {
    const language = this.languages.find((x) => x.id == event.target.value);
    if (language) {
      this.updateLanguage(language);
    }
  }

  private async updateLanguage(language: Language): Promise<void> {
    this.loadingService.show();
    try {
      this.language = language;
      let translations = await this.t.fetchLanguageTranslations(this.language);

      const allTranslations = this.keys.map((x) => {
        const translation = translations.find((y) => y.key == x) || new Translation();
        if (!translation.id) {
          translation.key = x;
          translation.language_id = language.id;
          translation.context = TranslateContext.common;
        }
        return translation;
      });

      translations = allTranslations.filter((translation) => !translation.key.startsWith('_"'));
      translations = translations.filter((translation) => translation.key.startsWith('_'));

      this.translations = translations;
    } catch (err) {
      this.alertService.handleHttpError(err);
    }
    this.loadingService.hide();
  }

  public async onSave(): Promise<void> {
    this.loadingService.show();
    try {
      await this.t.saveTranslations(this.translations);
      await this.updateLanguage(this.language);
      this.alertService.alertSuccess(this.t.translate('_SAVE_SUCCESS'));
    } catch (err) {
      this.alertService.handleHttpError(err);
    }
    this.loadingService.hide();
  }
}
